exports.components = {
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx?export=default" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx?export=default" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "slice---src-components-slices-footer-tsx": () => import("./../../../src/components/Slices/Footer.tsx?export=default" /* webpackChunkName: "slice---src-components-slices-footer-tsx" */),
  "slice---src-components-slices-header-tsx": () => import("./../../../src/components/Slices/Header.tsx?export=default" /* webpackChunkName: "slice---src-components-slices-header-tsx" */)
}



exports.head = {
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx?export=head" /* webpackChunkName: "component---src-pages-links-tsxhead" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx?export=head" /* webpackChunkName: "component---src-templates-page-tsxhead" */)
}

